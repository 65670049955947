import { Button } from "primereact/button";
import { useRef, useState } from "react";
import { Message } from 'primereact/message';

// currentImage will be string if it was a previously uploaded image, File otherwise
export default function ImageUpload({ currentImage, onChange }) {
  const [errorTooLarge, setErrorTooLarge] = useState(false)
  const fileInputRef = useRef(null);

  function openFilePicker(e) {
    e.preventDefault()
    fileInputRef.current.click()
  }

  function onSelectImage(e) {
    setErrorTooLarge(false)
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) { // 5mb
        setErrorTooLarge(true)
        return
      }
      onChange(e.target.files[0])
    }
  }

  function removeImage() {
    onChange(null)
  }

  return (
    <>
      { errorTooLarge && (
        <Message className="w-full mb-2" severity="error" text="File too large. Max size 5 MB." />
      )}

      <div className="flex items-start gap-2">
        { currentImage ? (
          <div className="relative w-24 h-24">
            <img
              alt="Upload preview"
              className="max-w-full max-h-full object-contain rounded"
              src={typeof currentImage === 'string' ? currentImage : URL.createObjectURL(currentImage)}
            />
          </div>
        ) : (
          <div
            className="
              w-24 h-24
              border-2 border-dashed border-gray-300
              rounded-lg
              flex justify-center items-center
              cursor-pointer
            "
            onClick={openFilePicker}
          >
            <span className="pi pi-image text-2xl text-gray-400" />
          </div>
        )}

        <div className="flex flex-col gap-1">
          <Button
            label={ currentImage ? "Change image" : "Choose image" }
            // icon="pi pi-folder-open"
            className="p-button-secondary p-button-sm !border-none !bg-gray-400"
            onClick={openFilePicker}
          />
          { currentImage && (
            <Button
              label="Remove image"
              className="p-button-danger p-button-sm !border-none !bg-red-400"
              onClick={removeImage}
            />
          )}
        </div>

        <input
          ref={fileInputRef}
          type="file"
          name="image input"
          accept="image/png,image/jpeg,image/webp"
          className="hidden"
          onChange={onSelectImage}
        />
      </div>
    </>
  );
}
