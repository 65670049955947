import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useLogger } from '../hooks/useLogger';
import { editWishlistItem, WishlistItem  } from '../services/wishlists';
import ImageUpload from './ImageUpload';

const StyledDialog = styled(Dialog)({
  width: '750px',
})

const StyledLabel = styled.label({
  marginTop: '8px',
  marginBottom: '4px',
  fontWeight: 'bold',
  display: 'block'
})

const StyledInput = styled(InputText)({
  width: '100%',
  '::placeholder': {
    opacity: '0.6'
  }
})

interface Props {
  visible: boolean
  onHide: () => void
  wishlistId: string
  item: WishlistItem
}

export function EditItemModal({ visible, onHide, wishlistId, item }: Props) {
  const [itemTitle, setItemTitle] = useState('')
  const [itemUrl, setItemUrl] = useState('')
  const [itemPrice, setItemPrice] = useState('')
  const [itemDescription, setItemDescription] = useState('')
  const [itemImage, setItemImage] = useState<File | string | null>(null);
  const [isLoading, setLoading] = useState(false)
  const logger = useLogger()

  function resetForm(item?: WishlistItem) {
    setItemTitle(item?.title || '')
    setItemUrl(item?.url || '')
    setItemPrice(item?.price || '')
    setItemDescription(item?.description || '')
    setItemImage(item?.image_url || '')
  }

  function handleCancel() {
    if (isLoading) { return }
    resetForm()
    onHide()
  }

  useEffect(() => {
    resetForm(item)
  }, [item])

  async function submit() {
    try {
      setLoading(true)
      const res = await editWishlistItem({
        title: itemTitle,
        url: itemUrl,
        price: itemPrice,
        description: itemDescription,
        itemId: item.id,
        wishlistId: wishlistId,
        ...(itemImage instanceof File && { image: itemImage }),
        deleteImage: itemImage === null,
      })
      resetForm()
      onHide()
      return res
    } catch (err) {
      logger.error('error editing wishlist item', err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledDialog header="Edit item" visible={visible} onHide={handleCancel} draggable={false} dismissableMask>
      <p className='mb-4'>Edit the information for your item below. All fields are optional.</p>
      <StyledLabel htmlFor='item-title'>Title</StyledLabel>
      <StyledInput placeholder='What do you want?' id="item-title" value={itemTitle} onChange={(e) => setItemTitle(e.target.value)} />
      <StyledLabel htmlFor='item-url'>Url</StyledLabel>
      <StyledInput placeholder='www.example.com' id="item-url" value={itemUrl} onChange={(e) => setItemUrl(e.target.value)} />
      <StyledLabel htmlFor='item-price'>Price</StyledLabel>
      <StyledInput placeholder='How much does it cost?' id="item-price" value={itemPrice} onChange={(e) => setItemPrice(e.target.value)} />
      <StyledLabel htmlFor='item-description'>Description</StyledLabel>
      <StyledInput placeholder='Enter additional information here' id="item-description" value={itemDescription} onChange={(e) => setItemDescription(e.target.value)} />
      <StyledLabel className="!mb-2" htmlFor='new-item-image'>Image</StyledLabel>
      <ImageUpload currentImage={itemImage} onChange={setItemImage} />
      <Button className="float-right mt-3" icon={isLoading && 'pi pi-spinner pi-spin'} label="Save changes" onClick={submit} />
    </StyledDialog>
  );
}
