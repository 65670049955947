// TODO: send production logs to backend for debugging

export class Logger {
  should_console_log = process.env.NODE_ENV === 'development'

  enable_console_logging() {
    this.should_console_log = true
  }

  log(...args: any[]) {
    if (this.should_console_log) {
      console.log(...args)
    }
  }

  error(message: string, error?: Error | unknown) {
    if (this.should_console_log) {
      console.log(message)
      if (error) {
        console.error(error)
      }
    }
  }
}

const instance = new Logger()
;(window as any).logger = instance
export default instance
