import styled from "@emotion/styled";

// NOTE: this is not using the screen sizes defined in style_constants.ts
export const MobileOnly = styled.div({
  display: 'none',
  // [SMALL_SCREENS]: {
  '@media (max-width: 700px)': {
    display: 'block',
  },
})

// NOTE: this is not using the screen sizes defined in style_constants.ts
export const StationaryOnly = styled.div({
  display: 'block',
  // [SMALL_SCREENS]: {
  '@media (max-width: 700px)': {
    display: 'none',
  },
})
