import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { auth, db, storage } from '../firebase'
import logger from './Logger'

export type WishlistItem = {
  id: string,
  title: string,
  url: string,
  price: string,
  description: string,
  created: string,
  updated: string,
  created_by: string,
  image_url?: string,
}

export type Wishlist = {
  id: string,
  title: string,
  order: string[], // array of WishlistItem ids
  created: string, // ISO timestamp
  updated: string, // ISO timestamp
  created_by: string // firebase uid
  privacy: 'private' | 'public'
}

export async function createNewWishlist({ title }: { title: string }) {
  try {
    if (auth.currentUser) {
      return await addDoc(collection(db, 'wishlists'), {
        title: title,
        order: [] as string[],
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        created_by: auth.currentUser.uid,
        privacy: 'public'
      } as Wishlist)
    } else {
      throw new Error('not authenticated')
    }
  } catch (err) {
    logger.error('error creating new wishlist', err)
    throw err
  }
}

export async function editWishlist({ title, order, wishlistId }: { title?: string, order?: string[], wishlistId: string }) {
  try {
    if (auth.currentUser) {
      return await setDoc(doc(db, `wishlists/${wishlistId}`), {
        ...title && { title: title },
        ...order && { order: order },
        updated: (new Date()).toISOString(),
      } as Wishlist, { merge: true })
    } else {
      throw new Error('not authenticated')
    }
  } catch (err) {
    logger.error('error editing wishlist', err)
    throw err
  }
}

export async function addNewWishlistItem({ title, url, wishlistId, price, description, image }: { title: string, url: string, wishlistId: string, price: string, description: string, image?: File }) {
  try {
    if (auth.currentUser) {
      const wishlistItemId = crypto.randomUUID()
      let image_url
      if (image) {
        const user_id = auth.currentUser.uid
        const imageRef = ref(storage, `product_images/${user_id}/${wishlistItemId}`)
        await uploadBytes(imageRef, image)
        image_url = await getDownloadURL(imageRef)
      }
      return await setDoc(doc(db, `wishlists/${wishlistId}/items`, wishlistItemId), {
        title: title,
        url: url,
        price: price,
        description: description,
        created: (new Date()).toISOString(),
        updated: (new Date()).toISOString(),
        created_by: auth.currentUser.uid,
        ...(image_url && { image_url })
      } as WishlistItem)
    } else {
      throw new Error('not authenticated')
    }
  } catch (err) {
    logger.error('error adding new wishlist item', err)
    throw err
  }
}

export async function editWishlistItem({ itemId, title, url, price, description, wishlistId, image, deleteImage }: { itemId: string, title: string, url: string, price: string, description: string, wishlistId: string, image?: File, deleteImage?: boolean }) {
  try {
    if (auth.currentUser) {
      let image_url
      if (image) {
        const user_id = auth.currentUser.uid
        const imageRef = ref(storage, `product_images/${user_id}/${itemId}`)
        await uploadBytes(imageRef, image)
        image_url = await getDownloadURL(imageRef)
      }
      return await setDoc(doc(db, `wishlists/${wishlistId}/items`, itemId), {
        title: title,
        url: url,
        price: price,
        description: description,
        updated: (new Date()).toISOString(),
        ...(deleteImage ? { image_url: null } : image_url ? { image_url } : {})
      } as WishlistItem, { merge: true })
    } else {
      throw new Error('not authenticated')
    }
  } catch (err) {
    logger.error('error editing wishlist item', err)
    throw err
  }
}
