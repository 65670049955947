import { ReactElement } from "react";
import { useAuth } from "../hooks/useAuth";

export const AuthGuard = ({ children }: { children: ReactElement }) => {
  const { firebaseUser } = useAuth();
  if (!firebaseUser) {
    // doesn't work because it runs before auth has finished loading,
    // so redirects on refresh even  if actually logged in
    // TODO: show "you must be logged in to view this page" message
    // return <Navigate to="/" replace />;
  }
  return children;
};
