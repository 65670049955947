import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useLogger } from '../hooks/useLogger';
import { editWishlist, Wishlist } from '../services/wishlists';

const StyledDialog = styled(Dialog)({
  width: '500px',
})

const StyledInput = styled(InputText)({
  width: '100%',
})

interface Props {
  visible: boolean
  onHide: () => void
  wishlist: Wishlist
}

export function EditWishlistModal({ visible, onHide, wishlist }: Props) {
  const [wishlistTitle, setWishlistTitle] = useState('')
  const logger = useLogger()

  function handleClose() {
    setWishlistTitle('')
    onHide()
  }

  useEffect(() => {
    setWishlistTitle(wishlist?.title || '')
  }, [wishlist])

  async function submit() {
    try {
      const res = await editWishlist({ title: wishlistTitle, wishlistId: wishlist.id })
      setWishlistTitle('')
      onHide()
      return res
    } catch (err) {
      logger.error('error editing wishlist', err)
    }
  }

  return (
    <StyledDialog header="Edit wishlist" visible={visible} onHide={handleClose} draggable={false} dismissableMask>
      <StyledInput id="wishlist-title" value={wishlistTitle} onChange={(e) => setWishlistTitle(e.target.value)} />
      <Button className="float-right mt-3" label="Done" onClick={submit} />
    </StyledDialog>
  );
}
