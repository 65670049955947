import styled from '@emotion/styled';
import { signOut } from 'firebase/auth';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { Sidebar } from 'primereact/sidebar';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { MobileOnly, StationaryOnly } from './Utilities';
import { useAuth } from '../hooks/useAuth';
import { HEADER_HEIGHT } from '../constants/style_constants';

const StyledHeader = styled.header({
  backgroundColor: 'white',
  borderBottom: '2px solid black',
  height: HEADER_HEIGHT,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: '0',
  // position: 'sticky',
  // top: 0,
  // zIndex: 100,
})

const Logo = styled(Link)({
  marginLeft: '10px',
  fontSize: '2em',
  textDecoration: 'none',
  color: 'black',
})

const Spacer = styled.div({
  flexGrow: '1',
})

const SidebarButton = styled(Button)({
  width: '100%',
  textAlign: 'right',
  marginTop: '5px',
  color: 'var(--text-color) !important',
})

const RightButton = styled(Button)({
  marginRight: '10px',
})

export default function Header() {
  const user = useAuth()
  const menu = useRef<Menu | null>(null)
  const [isSidebarVisible, setIsSidebarVisible] = useState(false)
  const navigate = useNavigate()
  // const openNewWishlistModal = useModalStore((state: any) => state.openNewWishlistModal)
  function logOut() {
    signOut(auth)
    navigate('/')
  }
  const menuItems = [
    // { label: 'Profile', command: () => { /* TODO */ }},
    // { label: 'My wishlists', command: () => { navigate('/mywishlists') }},
    { label: 'Log out', command: logOut }
  ]

  return (
    <StyledHeader>
      <Logo to="/" className='title'>listwisher</Logo>
      <Spacer />
      <Sidebar visible={isSidebarVisible} position="right" onHide={() => setIsSidebarVisible(false)}>
      { user?.firebaseUser
        ? (
          <>
            {/* <SidebarButton label="New wishlist" onClick={() => { openNewWishlistModal(); setIsSidebarVisible(false); }} className="p-button-text p-button-plain" /> */}
            <SidebarButton label="My wishlists" onClick={() => { navigate('/mywishlists'); setIsSidebarVisible(false); }} className="p-button-text p-button-plain" />
            <SidebarButton label="Log out" onClick={() => { logOut(); setIsSidebarVisible(false); }} className="p-button-text p-button-plain" />
          </>
        ) : (
          <>
            <SidebarButton onClick={e => { navigate('/login'); setIsSidebarVisible(false); }} label="Log in" className="p-button-text p-button-plain" />
            <SidebarButton onClick={e => { navigate('/signup'); setIsSidebarVisible(false); }} label="Sign up" className="p-button-text p-button-plain" />
          </>
        )}
      </Sidebar>
      <MobileOnly><RightButton icon="pi pi-bars" onClick={() => setIsSidebarVisible(true)} className="p-button-text p-button-plain" /></MobileOnly>
      { user?.firebaseUser
        ? (
          <StationaryOnly>
            {/* <RightButton label="New Wishlist" onClick={openNewWishlistModal} /> */}
            <Link to="/mywishlists"><Button label="My wishlists" className="p-button-text" /></Link>
            <Menu model={menuItems} popup ref={menu} />
            <RightButton onClick={(e) => menu?.current?.toggle(e) } className="p-button-text !text-black" label={user.firebaseUser.email!} icon="pi pi-angle-down" iconPos="right" />
          </StationaryOnly>
        ) : (
          <StationaryOnly>
            <RightButton onClick={e => navigate('/login')} label="Log in" className="p-button-text p-button-plain !text-black" />
            <RightButton onClick={e => navigate('/signup')} label="Sign up" />
          </StationaryOnly>
        )}
    </StyledHeader>
  );
}
