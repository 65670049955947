import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogger } from '../hooks/useLogger';
import { createNewWishlist } from '../services/wishlists';
import { useModalStore } from '../state/modals';

const StyledDialog = styled(Dialog)({
  width: '500px',
})

const StyledInput = styled(InputText)({
  width: '100%',
})

interface Props {
  visible: boolean
  onHide: () => void
}

export function NewWishlistModal({ visible, onHide }: Props) {
  const [newWishlistTitle, setNewWishlistTitle] = useState('')
  const navigate = useNavigate()
  const logger = useLogger()
  const openCreateAccountPromptModal = useModalStore((state: any) => state.openCreateAccountPromptModal)

  function closeModal() {
    setNewWishlistTitle('')
    onHide()
  }

  async function submit() {
    try {
      const res = await createNewWishlist({ title: newWishlistTitle })
      closeModal()
      navigate(`/wishlists/${res.id}`)
    } catch (err) {
      if (err instanceof Error && err.message === 'not authenticated') {
        closeModal()
        openCreateAccountPromptModal()
      } else {
        // TODO: display error
        logger.error('error submitting new wishlist', err)
      }
    }
  }

  return (
    <StyledDialog header="Choose a title for your new wishlist" visible={visible} onHide={closeModal} draggable={false} dismissableMask>
      <StyledInput id="new-wishlist-title" value={newWishlistTitle} onChange={(e) => setNewWishlistTitle(e.target.value)} />
      <Button className="float-right mt-3" label="Create wishlist" onClick={submit} />
    </StyledDialog>
  );
}
