export const MAX_PIXELS_SMALL = 500
export const MIN_PIXELS_MED = 501
export const MAX_PIXELS_MED = 1000
export const MIN_PIXELS_LARGE = 1001

export const SMALL_SCREEN_MAX_BREAKPOINT = `${MAX_PIXELS_SMALL}px`
export const MED_SCREEN_MIN_BREAKPOINT = `${MIN_PIXELS_MED}px`
export const MED_SCREEN_MAX_BREAKPOINT = `${MAX_PIXELS_MED}px`
export const LARGE_SCREEN_MIN_BREAKPOINT = `${MIN_PIXELS_LARGE}px`

export const SMALL_SCREENS = `@media (max-width: ${SMALL_SCREEN_MAX_BREAKPOINT})`
export const NOT_SMALL_SCREENS = `@media (min-width: ${MED_SCREEN_MIN_BREAKPOINT})`
export const MED_SCREENS = `@media (min-width: ${MED_SCREEN_MIN_BREAKPOINT}) and (max-width: ${MED_SCREEN_MAX_BREAKPOINT})`
export const LARGE_SCREENS = `@media (min-width: ${LARGE_SCREEN_MIN_BREAKPOINT})`

// export const MAIN_GRADIENT = 'linear-gradient(#FED84A, #FAA834)' // orange/yellow
// export const MAIN_GRADIENT = 'linear-gradient(#14cee3, #0d7ad9)' // cyan/blue
// export const MAIN_GRADIENT = 'white'
// export const MAIN_GRADIENT = 'linear-gradient(#fde68a, white)' // tailwind amber
// export const MAIN_GRADIENT = 'linear-gradient(#0d7ad9, white)' // blue from original gradient
// export const MAIN_GRADIENT = 'linear-gradient(#0284c7, white)' // tailwind sky-600
// export const MAIN_GRADIENT = 'linear-gradient(#4f46e5, #eef2ff)' // tailwind indigo-600
export const MAIN_GRADIENT = 'linear-gradient(#3b82f6, white)' // tailwind blue-500
// export const MAIN_GRADIENT = 'linear-gradient(#60a5fa, white)' // tailwind blue-400
// export const MAIN_GRADIENT = 'linear-gradient(#eff6ff, white)' // tailwind blue-50
// export const MAIN_GRADIENT = 'linear-gradient(#ccc, white)'
// export const MAIN_GRADIENT = '#eee'

export const HEADER_HEIGHT = '60px'
