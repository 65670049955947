import { Link } from "react-router-dom";

function FooterLink({ children, ...props }) {
  return <Link className="no-underline text-white" {...props}>{ children }</Link>
}

export default function Footer() {
  return (
    <footer className="py-12 bg-gray-600 text-white text-center flex flex-col gap-3 sm:gap-4">
      <nav className="flex gap-6 text-sm justify-center max-sm:flex-col max-sm:gap-3">
        <FooterLink to="/about">About</FooterLink>
        <FooterLink to="/contact">Contact</FooterLink>
        <FooterLink to="/gift-guides">Gift Guides</FooterLink>
        <FooterLink to="/terms">Terms of Service</FooterLink>
        <FooterLink to="/privacy">Privacy Policy</FooterLink>
      </nav>
      <p className="text-sm">&copy; { (new Date()).getFullYear() } Listwisher. All rights reserved.</p>
      <section className="flex gap-3 justify-center">
        <a href="https://www.facebook.com/people/Listwisher/61558733384903/" target="_blank" rel="noreferrer" className="text-white text-base"><span className="pi pi-facebook" /></a>
        <a href="https://x.com/listwisher" target="_blank" rel="noreferrer" className="text-white text-base"><span className="pi pi-twitter" /></a>
        <a href="https://www.instagram.com/listwisher/" target="_blank" rel="noreferrer" className="text-white text-base"><span className="pi pi-instagram" /></a>
      </section>
    </footer>
  )
}
