import { ReactNode } from 'react'
import { createContext } from 'react'
import logger, { Logger } from '../services/Logger'

export const LoggerContext = createContext<Logger>(logger)

export function LoggerProvider({ children }: { children?: ReactNode }) {
  return (
    <LoggerContext.Provider value={logger}>{children}</LoggerContext.Provider>
  )
}
