import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const StyledDialog = styled(Dialog)({
  width: '500px',
})

interface Props {
  visible: boolean
  onHide: () => void
  header?: string
  message?: string
  onConfirm?: () => void
  onDeny?: () => void
}

export function ConfirmationModal({ visible, onHide, header, message, onConfirm, onDeny }: Props) {
  async function handleDeny() {
    if (onDeny) onDeny()
    onHide()
  }

  async function handleConfirm() {
    if (onConfirm) onConfirm()
    onHide()
  }

  return (
    <StyledDialog header={header || 'Are you sure?'} visible={visible} onHide={handleDeny} draggable={false} dismissableMask>
      <p>{message || 'Are you sure?'}</p>
      <Button className="float-right mt-4" label="Yes, I'm sure" onClick={handleConfirm} />
      <Button className="float-right mt-4 mr-2 p-button-text p-button-secondary" label="Cancel" onClick={handleDeny} />
    </StyledDialog>
  );
}
