import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'primereact/resources/themes/lara-light-blue/theme.css';  //theme
import 'primereact/resources/primereact.min.css';                //core css
import 'primeicons/primeicons.css';                              //icons
import './index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import { LoggerProvider } from './state/logger';
import { AuthProvider } from './state/auth';
import { HelmetProvider, Helmet } from 'react-helmet-async';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>Listwisher - Create and share wishlists</title>
        <meta name="keywords" content="wishlist, wishlists, wish, list, maker, creator, gifts, shopping, birthday, holiday, christmas, wedding, registry, baby shower, ideas" />
        <meta
          name="description"
          content="Create and share wishlists online. Perfect for birthdays, holidays, wedding registries, baby showers, or just keeping track of things you want."
        />
      </Helmet>
      <LoggerProvider>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </LoggerProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
