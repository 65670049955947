import { onAuthStateChanged, User } from 'firebase/auth'
import { useState, useEffect, ReactNode } from 'react'
import { createContext } from 'react'
import { auth } from '../firebase'

type UserContext = {
  firebaseUser: User | null
}

export const AuthContext = createContext<UserContext>({ firebaseUser: null })

export function AuthProvider({ children }: { children?: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setUser(user)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ firebaseUser: user }}>{children}</AuthContext.Provider>
  )
}
