import { lazy } from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import { AuthGuard } from "./components/AuthGuard";
import Layout from "./Layout";

const Landing = lazy(() => import('./pages/Landing'));
const WishlistPage = lazy(() => import('./pages/Wishlist'));
const AboutPage = lazy(() => import('./pages/About'));
const SignupPage = lazy(() => import('./pages/Signup'));
const LoginPage = lazy(() => import('./pages/Login'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const MyWishlistsPage = lazy(() => import('./pages/MyWishlists'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicy'));
const TermsPage = lazy(() => import('./pages/Terms'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const GiftGuides = lazy(() => import('./pages/gift-guides'));
const MothersDay = lazy(() => import('./pages/gift-guides/MothersDay'));

export const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/wishlists/:wishlistId",
        element: <WishlistPage />,
      },
      {
        path: "/signup",
        element: <SignupPage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/mywishlists",
        element: <AuthGuard><MyWishlistsPage /></AuthGuard>,
      },
      {
        path: "/gift-guides",
        element: <GiftGuides />,
      },
      {
        path: "/gift-guides/mothers-day",
        element: <MothersDay />
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicyPage />
      },
      {
        path: "/terms",
        element: <TermsPage />
      },
      {
        path: "/contact",
        element: <ContactPage />
      },
      {
        path: "/*",
        element: <NotFoundPage />,
      },
    ]
  }
]);
