import create from 'zustand'

export const MODALS = Object.freeze({
  NEW_WISHLIST_MODAL: 'NEW_WISHLIST_MODAL',
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  EDIT_ITEM_MODAL: 'EDIT_ITEM_MODAL',
  EDIT_WISHLIST_MODAL: 'EDIT_WISHLIST_MODAL',
  CREATE_ACCOUNT_PROMPT_MODAL: 'CREATE_ACCOUNT_PROMPT_MODAL',
})

export const useModalStore = create((set) => ({
  modals: {},
  openModal: (modalName: string, data?: any) => set((state: any) => ({ modals: { ...state.modals, [modalName]: data || {} }})),
  closeModal: (modalName: string) => set((state: any) => ({ modals: { ...state.modals, [modalName]: false} })),
  closeAllModals: () => set(() => ({ modals: {} })),
  openNewWishlistModal: () => set((state: any) => ({ modals: { ...state.modals, [MODALS.NEW_WISHLIST_MODAL]: {} }})),
  openCreateAccountPromptModal: () => set((state: any) => ({ modals: { ...state.modals, [MODALS.CREATE_ACCOUNT_PROMPT_MODAL]: {} }}))
}))
