
import styled from '@emotion/styled';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';

const StyledDialog = styled(Dialog)({
  width: '500px',
})

interface Props {
  visible: boolean
  onHide: () => void
}

export function CreateAccountPromptModal({ visible, onHide }: Props) {
  const navigate = useNavigate()

  async function navigateToCreateAccount() {
    onHide()
    navigate('/signup')
  }

  async function navigateToLogin() {
    onHide()
    navigate('/login')
  }

  return (
    <StyledDialog header={"You aren't logged in"} visible={visible} onHide={onHide} draggable={false} dismissableMask>
      <p>Create a free account or log in in order to create your list.</p>
      <Button className="float-right mt-4" label="Create account" onClick={navigateToCreateAccount} />
      <Button className="float-right mt-4 mr-2 p-button-secondary" label="Log in" onClick={navigateToLogin} />
    </StyledDialog>
  );
}
